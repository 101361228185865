<template>
    <div class="GoodsPlanCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="formdata"
            :rules="rules"
            ref="createForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                    <el-row style="margin-top: 16px">
                        <el-col :span="8">
                            <el-form-item label="机构组名称">
                                <el-input placeholder="关键字" v-model="form.deptGroupName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input v-model="editData.creator" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-date-picker v-model="editData.createDate" format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="handleSave" size="small" :disabled="formdata.tableData.length <= 0"
                    >保存</el-button
                >
                <el-button type="primary" @click="handleAdd" size="small">添加方案</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <div style="color: red">注意：如果没有建立购买数量为1的方案，请建立一种，否则将无法启用任何方案</div>
                <el-table
                    id="printMe"
                    border
                    stripe
                    style="width: 100%"
                    :data="formdata.tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="500"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" />
                    <el-table-column label="计划名称" width="160" prop="name" />
                    <el-table-column label="商品名称" width="160" prop="goodsName" />
                    <el-table-column label="购买数量" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.counts'" :rules="rules.counts">
                                <el-input v-model="scope.row.counts" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="赠送数量" width="160">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'tableData.' + scope.$index + '.presentCount'"
                                :rules="rules.presentCount"
                            >
                                <el-input v-model="scope.row.presentCount" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="售价" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.price'" :rules="rules.price">
                                <ef-price-input v-model="scope.row.price" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    pricesValidateRule,
    remarkValidateRule,
} from 'js/validate/ValidateCommonRule';
import {
    abstractCodeValidateRule,
    countGreaterThanZeroValidateRule,
    countGreaterThanEqualZeroValidateRule,
} from 'js/validate/ValidateStockBizRule';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'GoodsPlanCreate',
    components: { EfPriceInput },
    props: ['form'],
    data() {
        return {
            editData: {
                BizCode: '',
                BizName: '',
                search: '',
                createDate: new Date(),
                creator: this.$store.state.session.name,
            },
            formdata: {
                tableData: [],
            },
            url: {
                page: '/goods/sku/createExtend',
                save: '/merchandising/detail/save',
            },
            loading: false,
            meta: {
                brands: [],
            },
            current: {},
            rules: {
                deptCode: deptCodeValidateRule(),
                abstractCode: abstractCodeValidateRule(),
                remark: remarkValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                price: pricesValidateRule(),
                counts: countGreaterThanZeroValidateRule(),
                presentCount: countGreaterThanEqualZeroValidateRule(),
            },
        };
    },
    mounted() {},
    methods: {
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                const _this = this;
                const _params = {
                    countList: _this.formdata.tableData.map((d) => d.counts),
                    presentCountList: _this.formdata.tableData.map((d) => d.presentCount),
                    priceList: _this.formdata.tableData.map((d) => d.price),
                    bizCode: _this.form.code,
                };
                UrlUtils.PostRemote(_this, _this.url.save, _params, null, () => {
                    _this.goBackAndReload();
                });
            });
        },
        handleAdd() {
            const _this = this;
            this.formdata.tableData = this.formdata.tableData.concat({
                name: _this.form.name,
                goodsName: _this.form.goodsName,
            });
        },
        handleDelete(index) {
            const _a = this.formdata.tableData;
            _a.splice(index, 1);
            this.formdata.tableData = _a;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
